@import "core/index.scss";
.section {
	&.static-faq-banner {
		position: relative;
		z-index: 1;
		
		@include media {
			// margin: 0 2rem;
			// width: calc(100% - 4rem);
			overflow: hidden;
		}

		+ .static-wrap {
			margin-top: 1.8rem;
		}

		.banner-wrap {
			position: relative;
			max-width: 100%;
		}

		.banner-top {
			padding: 4rem 5.6rem;
			position: relative;
			z-index: 2;

			@include media {
				padding: 0rem 2rem 3.4rem;
			}
		}

			.top-title {
				@include font-regular;
				font-size: 4rem;
				letter-spacing: .025em;

				@include media {
					font-size: 2.4rem;
					line-height: 1.2em;
				}
			}

			.top-opts {
				display: flex;
				justify-content: flex-start;
				margin-top: 3.5rem;

				@include mediaMin {
					> * + * {
						margin-left: 2rem;
					}
				}

				@include media {
					display: block;
					margin-top: 2rem;

					> * {
						width: 100%;

						+ * {
							margin-top: 1.5rem;
						}
					}
				}

			}
		
		.banner-mid {
			padding: 0 5.6rem 2.4rem;

			@include media {
				padding: 0 2rem 2rem;
			}
		}

			.mid-seperator {
				@include font-semibold;
				border-radius: $radius-general;
				text-transform: uppercase;
				color: $color-white;
				font-size: 1.3rem;
				line-height: 2.2rem;
				margin-top: -1.1rem;
				margin-bottom: 1.6rem;
				background-color: $color-gray-dark;
				display: block;
				width: 5rem;
				text-align: center;

				@include media {
					margin: 0 0 2.6rem;
				}
			}

			.mid-scrollbtn {
				@include font-medium;
				font-size: 1.8rem;
				letter-spacing: .02em;
				display: inline-flex;
				align-items: center;
				justify-content: center;
				transition: background-color $transition-hover ease;
				height: 3.6rem;
				border-radius: $radius-general;
				padding: .1rem 1rem 0;
				margin-left: -1.2rem;

				i {
					font-size: 2.2rem;
					margin-right: .5rem;
					margin-top: -.3rem;
				}

				@include mediaMin {
					&:hover {
						background-color: $color-gray-dark;
						color: $color-white;
					}
				}
			}

		.banner-bottom {
			position: relative;
			z-index: 1;
			padding: 3rem 5.6rem;
			background-color: $color-bg-light;
			display: flex;
			align-items: center;
			justify-content: flex-start;

			@include media {
				display: block;
				padding: 2rem 2rem 3rem;
			}
		}

			.bottom-link {
				position: relative;
				z-index: 3;

				@include mediaMin {
					+ .bottom-link {
						margin-left: 3rem;
					}
				}

				@include media {
					+ .bottom-link {
						margin-top: 1.5rem;
					}
				}
			}

			.bottom-image {
				position: absolute;
				width: 52.6rem;
				right: 0;
				bottom: 4.4rem;
				z-index: 2;

				@include media {
					height: 16.1rem;
					width: auto;
					right: -5rem;
					bottom: 11.6rem;
				}
			}

	}
}