@import "core/index.scss";
@import '@wearetla/tla-essentials-tools/partials/collapser/collapser-mixins';

@include collapser() {
	font-size: 1.5rem;

	.collapser-btn {
		@include font-semibold;
		width: 100%;
		text-align: left;
		min-height: 6rem;
		display: flex;
		justify-content: space-between;
		flex-flow: row wrap;
		align-items: center;
		padding: 0;
		transition: color $transition-hover ease;
		
		&:after {
			width: 3.5rem;
			height: 3.5rem;
			border: .1rem solid $color-gray;
			border-radius: 50%;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 1rem;
			transition: transform $transition-hover ease;
			@include icon('angle-right');
		}

		@include mediaMin {
			&:hover {
				color: $color-blue;
			}
		}
	}

		.btn-text {
			font-weight: inherit;
			flex: 1 0 0;
			font-size: inherit;
		}

	.collapser-innerwrap {
		padding: 1rem 0 3.6rem;
		color: $color-text-light;
	}

	&.active.show {
		.collapser-btn {
			&:after {
				transform: rotate(90deg);
			}
		}
	}
}