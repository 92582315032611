$color-black: #000;
$color-white: #fff;

$color-orange: #f8762f;
$color-orange-text: #81563e;
$color-orange-bg: #fef0e8;

$color-orange-dark: #ff6000;

$color-orange-light: #ff9400;
$color-orange-light-bg: #fff3db;

$color-beige-bg: #eee8e6;

$color-blue: #0c61dc;
$color-blue-bg: #eff4fb; //#e6effb;
$color-blue-bg-dark: #c0d4f1;

$color-blue-medium: #2c67bb;

$color-blue-light: #8ac3fc;
$color-blue-light-bg: #e5f3fa;
// $color-blue-light-bg: #e7e4ef;

$color-purple: #6c0cc6;
// $color-purple-bg: #eed4ed;
$color-purple-bg: #EBE1F7;

$color-pink: #ec007c;
$color-pink-light: #e175b4;
$color-pink-bg: #fadce7;
$color-pink-bg-light: #fcecf1;

$color-pink-dark: #c0106c;
$color-pink-darker: #681c44;

$color-red: #ee2e24;
$color-red-bg: #fbd8dc;

$color-green: #1f88aa;
$color-green-bg: #d3ebdf;

$color-primary: $color-pink;
$color-primary-dark: $color-pink-dark;
$color-primary-bg: $color-pink-bg;

$color-bg: #f1f1f3;
$color-bg-light: #f8f9fa;
$color-bg-dark: #e2e7eb;

$color-gray-darker: #556274;
$color-gray-dark: #909fb8;
$color-gray: #d9dfe7;
$color-gray-light: #e4e9f0; //lighten($color-gray, 5%);

$color-text-dark: #10356a; // #2e394a; 
$color-text: #10356a;
$color-text-light: rgba($color-text, .7);
$color-text-lighter: rgba($color-text, .5);
$color-text-lightest: rgba($color-text, .3);

$color-text-blue: #2b50ad;
$color-text-blue-light: #a2b3c2;

// Brand Colors
$color-google-red: #ea4335;

// Effect Colors
$color-success: #017c64;
// $color-success-bg: rgba($color-success, .1);
$color-success-bg: #e5f7f4;
$color-error: #b80034;
// $color-error-bg: rgba($color-error, .1);
$color-error-bg: #fee8ef;
$color-warning: $color-orange;
$color-warning-bg: $color-orange-bg;

$color-autofill: rgb(232, 240, 254);
$color-placeholder: #f0f2f5;

// Font Weighting
$weight-extralight: 200;
$weight-light: 300;
$weight-regular: 400;
$weight-medium: 500;
$weight-semibold: 600;
$weight-bold: 700;
$weight-black: 800;

$fontweight-heading: $weight-semibold;
$fontweight-bold: $weight-semibold;

$darken-ratio-low: 1%;
$darken-ratio: 3%;
$darken-ratio-high: 5%;

$lighten-ratio-low: 10%;
$lighten-ratio: 15%;

//transition
$transition-superfast: 80ms;
$transition-fast: 180ms;
$transition-hover: 300ms;
$transition-anim-fast: 400ms;
$transition-anim: 600ms;
$transition-long: 800ms;

//radiusses
$radius-small: .2rem;
$radius-general: .3rem;
$radius-big: .8rem;

//easing
$ease-1: "cubic-bezier(0.32, 0.28, 0, 0.97)";

//shadows
$shadow-general: #{"0rem .5rem 1.0rem 0rem rgba(0, 0, 0, 0.05)"};
$shadow-input: #{"0rem .5rem 1.0rem 0rem rgba(0, 0, 0, 0.05)"};
$shadow-input-primary: #{"0rem .5rem 1.0rem 0rem rgba(236, 0, 124, 0.05)"};
$shadow-wide: #{"0rem .6rem 2.4rem 0rem rgba(0, 0, 0, 0.1)"};
$shadow-wide-primary: #{"0rem .6rem 2.4rem 0rem rgba(236, 0, 124, 0.05)"};
$shadow-big: #{".0rem 1.2rem 2.0rem .0rem rgba(58, 64, 81, 0.1)"};
$shadow-big-primary: #{".0rem 1.2rem 2.0rem .0rem rgba(236, 0, 124, 0.1)"};

// Misc
$slider-inline-arrow-width: 1360;

// Order Status Colors
$color-order-status-info: $color-primary;
$color-order-status-success: $color-success;

$color-yellow: #FFE500;
$color-yellow-dark: #E2CC07;
$color-yellow-light: #faef59;
$color-yellow-bg: #f4eba5;
$color-blue-dark: #1e2044;
$color-secondary: $color-yellow;
$color-secondary-light: $color-yellow-light;;