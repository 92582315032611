@import "core/index.scss";
$color-blue-light-dark: darken($color-blue-light, 10%);

.page.static {
	padding: 1.2rem 0 7.4rem;
}

.section {
	&.static-wrap {
		display: flex;
		flex-flow: row wrap;
		align-items: flex-start;
		justify-content: space-between;
		position: relative;

		&.fixed {
			.static-menu {
				position: fixed;
				top: 20px;
			}

			.static-content {
				margin-left: 25rem;
			}

			&.fixed-end {
				.static-menu {
					position: absolute;
					top: auto;
					bottom: 0;
				}
			}
		}

		@include media {
			display: block;
		}
	}

	&.static-search {
		width: 100%;
		display: flex;
		align-items: center;
		background-color: $color-bg-light;
		padding: 2rem;
		margin-bottom: 2rem;

		@include media {
			width: calc(100% + 4rem);
			margin: 0 -2rem 2rem;
			display: block;
		}

		&.active {
			.search-clearbtn {
				opacity: 1;
			}
		}

		.search-title {
			@include font-bold;
			width: 23rem;
			display: block;
			max-width: 100%;
			// text-align: right;
			// padding-right: 2rem;

			@include media {
				margin-bottom: 1.5rem;
			}

		}
			.title-icon {
				font-size: 2.4rem;
				margin-right: .9rem;
				margin-top: -.2em;
				display: inline-block;
				vertical-align: middle;
			}

		.search-inputwrap {
			position: relative;
			// flex: 1 0 0;
			
			@include mediaMin {
				width: 35%;
			}
		}

		.search-clearbtn {
			position: absolute;
			opacity: 0;
			top: 50%;
			right: 2rem;
			transform: translate3d(0, -50%, 0);
			z-index: 2;
			transition:
				background-color $transition-hover ease,
				color $transition-hover ease,
				border-color $transition-hover ease,
				opacity $transition-hover ease;
		}
	}
	
	&.static-menu {
		width: 23rem;
		border: .1rem solid $color-gray;
		padding: 2.4rem 2rem;
		// margin-right: 2rem;

		@include media {
			width: 100%;
			border: none;
			padding: 0;
		}

		.menu-title {
			@include font-bold;
			font-size: inherit;
			line-height: 1.3333em;
			display: flex;
			flex-flow: row wrap;
			align-items: center;
			margin-bottom: 1.5rem;

			@include media {
				border-top: .1rem solid $color-gray-light;
				border-bottom: .1rem solid $color-gray-light;
				padding: 2rem 2rem;
				font-size: 2.4rem;
				margin-bottom: 2.2rem;
			}

			i {
				font-size: 2.4rem;
				margin-right: .9rem;
			}
		}

		// .menu-list {}

			.list-item {
				@include font-semibold;
				padding: 1rem 0;

				&.placeholder {
					width: 80%;
					height: 2rem;
					margin-top: 1rem;

					+ .list-item {
						margin-top: 2rem;
					}
				}

				a {
					&.active {
						color: $color-blue;
					}
				}
			}

		.menu-selectnav{
			position: relative;
			// padding: 0 2rem;
			padding: 0;

			.selectnav-label {
				@include font-semibold;
				display: block;
				width: 100%;
				border: .1rem solid $color-gray-light;
				display: flex;
				justify-content: space-between;
				align-items: center;
				box-shadow: $shadow-input;
				font-size: 1.5rem;
				height: 6rem;
				padding: 0 1.5rem;
				border-radius: $radius-general;

				&:after {
					@include icon('angle-down');
					font-size: 1rem;
				}
			}

			.selectnav-select {
				opacity: 0;
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
			}
		}
	}

	&.static-content {
		border: .1rem solid $color-gray;
		width: calc(100% - 25rem);

		@include media {
			width: 100%;
			border: none;
		}

		.content-title {
			@include font-semibold;
			font-size: 2.4rem;
			border-bottom: .1rem solid $color-gray;
			padding: 2rem 4.4rem;
			line-height: 4rem;

			@include media {
				// padding: 0 2rem;
				// font-size: 1em;
				// color: $color-text-light;
				// border: none;
				display: none;
			}

			.placeholder {
				width: 30rem;
				max-width: 100%;
				height: 4rem;
			}
		}

		.content-list {
			padding: 0 4.4rem 1rem;
			font-size: 1.6rem;

			@include media {
				padding: 2rem 2rem 2rem;
			}

			.collapser {
				font-size: 1.6rem;

				.collapser-content {
					font-size: 1.5rem;
				}
			}

			.list-node {
				+ .list-node {
					border-top: .1rem solid $color-gray;
				}
			}
		}

		.content-text {
			padding: 3rem 4.4rem 4rem;
			color: $color-text-light;
			font-size: 1.6rem;

			@include media {
				padding: 3rem 2rem 3rem;
			}

			&:after {
				content: ' ';
				display: block;
				overflow: hidden;
				width: 100%;
				height: 0;
			}

			.placeholder {
				width: 80%;
				height: 4rem;

				&:nth-child(5n+2) {
					width: 100%;
					height: 7rem;
				}

				&:nth-child(5n+3) {
					width: 90%;
					height: 7rem;
				}

				&:nth-child(5n+4) {
					width: 75%;
					height: 5rem;
				}
				&:nth-child(5n+5) {
					width: 85%;
					height: 10rem;
				}
			}
		}
	}
}