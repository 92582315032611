@import "core/index.scss";
.emptymessage {
	color: $color-text-lighter;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	min-height: 10rem;

	&.lined {
		border: .1rem solid $color-gray-light;
		border-radius: $radius-big;
		padding: 2rem;
	}

	.emptymessage-icon {
		font-size: 3rem;
		display: block;
		width: 3rem;
		margin: 0 auto 1.5rem;
	}

	.emptymessage-content {
		text-align: center;
		line-height: 1.2em;

		> * + * {
			margin-top: 1.5rem;
		}
	}
}